import React from 'react'
import HubspotForm from 'react-hubspot-form'
import Layout from '../../../components/layout'

import {
	Container,
	Section,
} from '../../../components/layouts'

import {
	Subtitle,
	Paragraph,
	BlogAuthor,
	Link,
} from '../../../components/ui'

import powerPageHeading from '../../../assets/images/powerpage_heading.png'

import theme from '../../../theme/theme'

const {
	intro,
} = theme.sections

class IndexPage extends React.Component {

	componentDidMount() {
		window.scrollTo(0, -1500)
	}

	render () {
		return (
			<Layout>
				<Section id="intro" bg={intro.bg} paddingTop={'100px'}>
					<Container  >
						<img alt="Turning Millennials into Donors" src={powerPageHeading} width="100%"/>

						<Subtitle fontSize={'25px'} style={{ marginTop:'1em' }}>Actionable tips and methods for nonprofits looking to reach and engage millennials as active donors </Subtitle><br/>

						<Paragraph>
          Millennials--the generation rapidly changing the way we eat, work, exercise, shop, and now, the way we give. Characterized as the generation born between 1980 and 2000, millennials today range from the ages 18 to 38. With millennials now making up the largest portion of the population, it is imperative that nonprofits begin engaging and securing them as donors. But despite making up more than a quarter of the population, millennials account for only 11% of charitable giving.
							<br/><br/>This may come as a surprise, but millennials have really good intentions. Often referred to as the purpose-driven generation, millennials care about doing social good and yearn to make an impact on the world around them. But there’s a problem--according Blackbaud’s Next Generation of American Giving study, only 25% of millennials believe that monetary giving is the best way to make a difference with charitable organizations.
							<br/><br/>So how can you, as a nonprofit, turn well-meaning, “I’m-going-to-save-the-world” millennials into tangible donors? And even better--how can you turn them into recurring donors?
          In this guide, I cover seven approaches that will help your nonprofit both involve and capture the millennial generation.

							<ul>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter1/'}>Chapter 1: 3 ways to get Millennials directly engaged with your organization</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter2/'}>Chapter 2: Generate Social Currency (aka Millennial Candy)</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter3/'}>Chapter 3: 4 Methods of Transparency that Millennials Love</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter4/'}>Chapter 4: How To Make Your Social Media Attractive and Effective</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter5/'}>Chapter 5: Turn the Tables By Getting Millennials To Fundraise For You</Link></li>
								<li>Chapter 6: How to get Millennials to Pull the Trigger and Donate (in 2 minutes)</li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter7/'}>Chapter 7: Turning One-Time Donors into Lifetime Donors</Link></li>
							</ul>

							<Subtitle fontSize={'25px'} style={{ marginTop:'1em' }} id="chapter6">Chapter 6: How to get Millennials to Pull the Trigger and Donate (in 2 minutes)</Subtitle>
						Take just a moment to put yourself in a hypothetical situation. You’re a 25-year-old browsing Instagram while waiting for latte at Starbucks. You look through your feed and watch a powerful video about a 7-year-old Ugandan boy named Kato who doesn’t have school supplies. Right under that video you see a button that says “Donate $10 to buy a backpack of school supplies for a child like Kato”. You glance back at the video--how could you say no to a child as cute as Kato? You press the button, enter your email and your credit card number and it’s done--just in time for the barista to announce that your pumpkin spice latte is ready.
							<br/><br/>Sure, as a nonprofit you want more than a one-time $10 donation from a millennial, but understand that this is how you get your foot in the door. Now that you have their information and they’ve already donated to you, you have so much more traction to turn them into a regular donor.
							<br/><br/>So what was the secret here? The whole process--from clicking the “donate” button to seeing the “thank you for donating” screen--<strong>took less than 2 minutes</strong>.
							<br/><br/>Here are 5 things you’ll need to condense your donation process down to two minutes. Note that each of these are beneficial in and of themselves--so even if you can’t implement all to create a 2 minute donation process, just implementing some can be helpful.
							<ul>
								<li><strong>Get online</strong>:  You will get underwhelming fundraising support from millennials via snail mail. If you don’t already have a way for supporters to donate online, you are missing out on so much potential with your fundraising (and not only with millennials). 47% of millennials give online compared to only 10% who give by mailed check. </li>
								<li><strong>Go mobile</strong>: 62% of millennials said that they would be willing to give via their mobile phone. Much like social media, smartphones are second nature to millennials. If your donors aren’t able to give on their mobile device, they will need to remember to do it the next time they sit down at their computer. </li>
								<li><strong>Small asks</strong>: A millennial will be much quicker and more willing to respond to a $5 ask than an $100 one. Tie it to a specific cause. Text it, email it, and blast it on social media. For example, “Give $5 to buy a pair of glasses for someone who needs them” is a small and attainable ask for a millennial. </li>
								<li><strong>Lay it out: </strong>Be specific and direct. Tell them how much to donate for what cause specifically. Millennials aren’t going to waste time figuring that out themselves.  </li>
								<li><strong>Make it quick and easy:</strong> Remove complicated language and unnecessary information from your donation process. Make sure there is NO confusion. If a millennial is confused about the donation process, 9 out of 10 times they’ll tell themselves they’ll figure it out later and never come back to it. </li>
							</ul>
							<strong>**Need a way to mobile fundraise and process small donations?</strong> Check out our <Link style={{ display: 'inline' }} href={'https://itunes.apple.com/us/app/flourish-donations/id1356801492?mt=8'}>iOS</Link> or <Link style={{ display: 'inline' }} href="https://play.google.com/store/apps/details?id=com.flourish.flourish&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">android</Link> app.

							<hr style={{ margin: '1em 0' }}/>

							<Subtitle fontSize={'30px'}>
							Looking for the full e-book?
							</Subtitle>
							<Paragraph
								fontSize={'21px'}
								marginRight={"2em"}
								fontWeight={'200'}>Fill out this form to receive our free guide <span style={{ fontStyle: 'italic' }}>Millennials To Donors</span></Paragraph>
							<HubspotForm
								id="reactHubspotForm"
								portalId="4829666"
								formId="1c650ab1-5fd3-4250-88c3-269180d71e88"
								loading={<div>{'Loading...'}</div>}
							/>

							<hr style={{ margin: '1em 0' }}/>
							<br/>
							<br/>
              Now I’ll turn it over to you. What do you think of this guide? Is there anything I missed or you want to see covered? Please send me your thoughts at <Link style={{ display: 'inline' }} href="mailto:laura@flourishchange.com">laura@flourishchange.com</Link>.

						</Paragraph>
						<BlogAuthor author="laura"/>
					</Container>

				</Section>
			</Layout>
		)
	}
}

export default IndexPage
